<template>
  <div class="students">
    <div class="no-results" v-show="total === 0 && !spinShow">
      <img :src="require('@/assets/qietu/article.png')" alt="">
      <p>暂无关注</p>
    </div>
    <Row class="list" :gutter="16">
      <Col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" :xxl="8" v-for="item in list" :key="item.userInfo.userId">
        <div class="listItem">
          <div class="top">
            <div style="display: flex; align-items: center;flex-grow: 1;">
              <div class="lastName">
                  <img
                    :src="$global.imgUrl + item.userInfo.headPhoto"
                    alt=""
                    class="headImg"
                    v-if="item.userInfo.headPhoto"
                  />
                  <img
                    class="headImg"
                    v-else
                    :src="require('@/assets/imgs/head_bg.png')"
                  />
              </div>
              <div class="firstName">
                <span class="nameBox">{{item.userInfo.realname}}</span>
                <img
                  style="width: 18px; height: 18px"
                  src="@/assets/svg/xiaoren.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="right">
              <Button class="blue" @click="unfocus(item.userInfo.userId)">已关注</Button>
              <div class="none" @click="toMessages(item.userInfo.userId,item.userInfo.reviewStatus,item.userInfo.realname,item.userInfo.headPhoto)">私信</div>
            </div>
          </div>
          <div class="userList">
            <img src="@/assets/svg/icon-sch_02.svg" alt="" />
            <img src="@/assets/svg/icon-sch_03.svg" alt="" />
            <img src="@/assets/svg/icon-sch_04.svg" alt="" />
            <img src="@/assets/svg/icon-sch_05.svg" alt="" />
            <img src="@/assets/svg/icon-sch_06.svg" alt="" />
            <img src="@/assets/svg/icon-sch_07.svg" alt="" />
            <img src="@/assets/svg/icon-sch_08.svg" alt="" />
            <img src="@/assets/svg/icon-sch_09.svg" alt="" />
          </div>
          <div class="bottom">
            <div>
              <span>职称：</span
              ><span v-for="obj in item.title" :key="obj.id"
                >{{ obj.levelThreeName }}&nbsp;</span
              >
            </div>
            <div>
              <span>研究领域：</span
              ><span v-for="obj in item.subject" :key="obj.id"
                >{{ obj.levelTwoName }} &nbsp;</span
              >
            </div>
            <div>
              <span>学者机构：</span><span>{{ item.userInfo.unit }}</span>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <Paging
      :total="total"
      :curPage="page"
      :limit="16"
      :showPage="5"
      @changePager="handlePager"
    ></Paging>
    <Spin size="large" fix v-show="spinShow"></Spin>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { followStudent,unfocusScholarApi } from "../../../../../apis/user";
import Paging from "@/components/paging/index.vue";
export default {
  components: {
    Paging,
  },
  name: "students",
  data() {
    return {
      total: 0,
      page: 1,
      list: [],
      spinShow: false
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  methods: {
    toMessages(id,type,name,foto) {
      this.$router.push({name:'letterUser',params:{id,name,foto,type}})
    },
    async unfocus(id) {
      if (this.hasLogin) {
        const {resultDesc} = await unfocusScholarApi({scholarId: id});
        if (resultDesc === '成功!') {
          this.getList()
          this.$Message.success('取消关注成功');
        } else {
          this.$Message.error(resultDesc);
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    handlePager(i) {
      this.page = i;
      this.getList()
    },
    async getList() {
      this.spinShow = true
      let obj = {
        pageNo: this.page,
        pageSize: 16
      };
      const { data } = await followStudent(obj);
      this.total = Number(data.total);
      this.list = data.list;
      this.spinShow = false
    },
  },
};
</script>

<style lang="scss" scoped>
.students {
  position: relative;
  margin-top: 35px;
  p {
    text-align: center;
    font-size: 20px;
  }
  .list {
    .listItem {
      margin-bottom: 30px;
      padding: 25px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .lastName {
          width: 62px;
          height: 62px;
          text-align: center;
          color: #444444;
          line-height: 62px;
          background: #f6f6f6;
          border: 1px solid #f6f6f6;
          border-radius: 50%;
          overflow: hidden;
          .headImg {
            width: 100%;
            height: 100%;
          }
        }
        .firstName {
          margin-left: 10px;
          .nameBox {
            display: inline-block;
            max-width: 150px;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 26px;
            color: #333333;
            vertical-align: middle;
          }
          img {
            margin-left: 5px;
            vertical-align: middle;
          }
        }
        .right {
          font-size: 14px;
          width: 82px;
          .blue {
            cursor: pointer;
            width: 82px;
            height: 34px;
            color: #00a4ff;
            border: 1px solid #00a4ff;
            border-radius: 10px;
            text-align: center;
            line-height: 34px;
            margin-bottom: 7px;
          }

          .none {
            margin-top: 7px;
            cursor: pointer;
            color: #999999;
            width: 82px;
            height: 34px;
            border: 1px solid #999999;
            border-radius: 10px;
            //margin-left: 30px;
            text-align: center;
            line-height: 34px;
          }
        }
      }
      .userList {
        margin-top: 10px;
        height: 20px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .bottom {
        margin-top: 45px;

        div {
          line-height: 26px;
          font-weight: 400;
          color: #444444;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .no-results {
    margin: 0 auto;
    width: 500px;
    // height: 300px;
    img {
      width: 100%;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #999999;
    }
  }
}
</style>
